<template>
  <div class="send-file">
    <b-modal
      id="sendFileUploadAccess"
      centered
      size="md"
      hide-footer
      @shown="getData"
    >
      <template #modal-header>
        <div class="membersendFile-header text-center">
          <h4>Send Upload Link</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="sendInfo-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(sendEmail)"
              ref="sendEmailContainer"
            >
              <div class="action-icons">
                <custom-button
                  class-name="float-right"
                  title="Add Email"
                  iconClass="fa fa-plus"
                  @click.native="addEmail"
                />
              </div>
              <div class="clearfix"></div>

              <b-card v-for="(input, i) in form.data" :key="i" class="file-card">
                <div class="form-group row">
                  <b-form-group class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                    <validation-provider
                      name="name"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        :state="getValidationState(validationContext)"
                        type="name"
                        labelFor="name"
                        labelName="Name"
                        inputName="name"
                        v-model="input.name"
                        placeholder="Enter name"
                      />
                      <b-form-invalid-feedback
                        id="input-feedback"
                        v-if="apiErrors || validationContext.errors"
                      >
                        {{
                          apiErrors && apiErrors[`data.${i}.name`][0]
                            ? apiErrors[`data.${i}.name`][0]
                            : validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                    <validation-provider
                      name="email"
                      :rules="{ required: true, email: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        :state="getValidationState(validationContext)"
                        type="email"
                        labelFor="email"
                        labelName="Email"
                        inputName="email"
                        v-model="input.email"
                        placeholder="Enter email"
                      />
                      <b-form-invalid-feedback
                        id="input-feedback"
                        v-if="apiErrors || validationContext.errors"
                      >
                        {{
                          apiErrors && apiErrors[`data.${i}.email`][0]
                            ? apiErrors[`data.${i}.email`][0]
                            : validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group class="col-xxl-12 col-xl-12 col-lg-12 mb-3">
                    <validation-provider
                      name="message"
                      :rules="{ required: false }"
                      v-slot="validationContext"
                    >
                      <b-form-textarea
                        id="message"
                        :state="getValidationState(validationContext)"
                        v-model="input.message"
                        placeholder="Enter message"
                      ></b-form-textarea>

                      <b-form-invalid-feedback
                        id="input-feedback"
                        v-if="apiErrors || validationContext.errors"
                      >
                        {{
                          apiErrors && apiErrors[`data.${i}.message`][0]
                            ? apiErrors[`data.${i}.message`][0]
                            : validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>

                  <div
                    class="remove-email-action action-icons"
                  >
                    <custom-button
                      class-name="float-right"
                      title="Delete"
                      iconClass="fas fa-trash-alt"
                      @click.native="removeEmail(i)"
                    />
                  </div>
                </div>
              </b-card>

              <div
                class="form-group col-xxl-12 col-xl-12 col-lg-12 mb-3 pull-right"
              >
                <b-button
                  class="univ-btn send-email-btn"
                  type="submit"
                  :disabled="isSend"
                >
                  {{ isSend ? "Sending ..." : "Send Email" }}
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
let openAxiosInstance = axios.create({ headers: "" });
openAxiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
const formData = {
  data: [],
  file_url: "",
  platform_user_id: "",
  platform_user_type: "",
};

export default {
  name: "FileUploadAccess",
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    form: { ...formData },
    isSend: false,
    apiErrors: null,
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.apiErrors) {
        valid = false;
      }
      return dirty || validated ? valid : null;
    },
    addEmail() {
      this.apiErrors = null;
      this.form.data.push({
        name: "",
        email: "",
        message: "",
      });
    },
    removeEmail(index) {
      this.form.data.splice(index, 1);
    },
    async sendEmail() {
      const url = `api.access/v3/file-share/add-upload-access`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendEmailContainer,
      });
      await openAxiosInstance
        .post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.form = { ...formData };
          this.closeModal();
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
          this.isSend = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("sendFileUploadAccess");
    },
    getData() {
      this.form = {
        data: [],
        platform_user_id: this.rep.id,
        platform_user_type: "REP",
      };
      /**
       * creating first input email
       */
      this.addEmail();
    },
  },
  watch: {
    /**
     * preventing input value ,at least one input will be shown
     * cannot delete remaining input element
     */
    "form.data"(val) {
      if (val.length < 1) {
        this.addEmail();
      }
    },
  },
};
</script>
<style scoped>
.send-email-btn {
  max-width: none;
}
.float-right {
  float: right;
  margin-right: 20px;
}
.remove-email-action {
  margin-top: -4px;
  margin-left: 18px;
}

.file-card {
  margin-bottom:5px;
}
</style>
