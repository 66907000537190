<template>
  <div>
    <simple-table :columns="columns">
      <template v-slot:tableBody>
        <tbody>
          <tr v-if="!rows">
            <td :colspan="5" align="center">No records found.</td>
          </tr>
          <tr v-for="(row, index) in rows" :key="index">
            <td width="15%">
              <span class="date">
                {{
                  row.doc_date
                    | date_format("YYYY/MM/DD HH:mm:ss", "MM/DD/YYYY")
                }}
              </span>
              <span class="time">
                {{
                  row.doc_date | date_format("YYYY/MM/DD HH:mm:ss", "hh:mm A")
                }}
              </span>
            </td>

            <td width="15%">
              <div>
                  Title :  {{ row.file_title }}
              </div>
              <div v-if="row.uploaded_by">
                Uploaded By :{{ row.uploaded_by }}
              </div>
                
            </td>
            <td width="30%">
              <div :id="'file-' + row.doc_id">
                <template v-if="showFileId == row.doc_id">
                  {{ row.doc_notes }}
                </template>
                <template v-else>
                  {{ row.doc_notes | truncate(100) }}
                </template>
              </div>

              <div v-if="row.doc_notes.length >= 100">
                <a
                  href="javascript:void(0)"
                  class="float-right toogle-note"
                  @click="toggleNote(row.doc_id)"
                >
                  {{ showFileId == row.doc_id ? "Show Less" : "Show More" }}
                </a>
              </div>
            </td>
            <td>
              <strong>{{ row.doc_type }}</strong>
            </td>
            <td width="15%">
              <div class="action-icons">
                <custom-button
                  title="Send Download Link"
                  iconClass="fa fa-send"
                  @click.native="sendDownloadLink(row)"
                />

                <custom-button
                  title="View Detail"
                  iconClass="fas fa-eye"
                  @click.native="viewDetail(row)"
                />
                <custom-button
                  title="Delete"
                  iconClass="fas fa-trash-alt"
                  @click.native="deleteRow(row.doc_id)"
                />
                <!-- <delete-button /> -->
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </simple-table>
    <file-view :row.sync="row" @onCloseModal="row = {}" />
    <file-download-access :row.sync="row" @onCloseModal="row = {}" />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import FileView from "./FileView";
import FileDownloadAccess from './FileDownloadAccess.vue';
export default {
  name: "FileListTable",
  components: { FileView,FileDownloadAccess },
  props: {
    rows: {
      required: true,
    },
  },
  data: () => ({
    columns: [
      { label: "Date" },
      { label: "File " },
      { label: "File Note" },
      { label: "Type" },
      { label: "Action" },
    ],
    row: {},
    showFileId: "",
  }),
  methods: {
    sendDownloadLink(row) {
      this.row = row;
      this.$bvModal.show("sendFileDownloadAccess");
    },
    toggleNote(rowId) {
      if (this.showFileId == rowId) {
        this.showFileId = "";
      } else {
        this.showFileId = rowId;
      }
    },
    viewDetail(row) {
      this.row = row;
      this.$bvModal.show("viewFile");
    },
    deleteRow(rowId) {
      const url = `v2/doc/delete/${rowId}`;
      this.$confirm({
        title: "Delete File",
        message: `Do you want to delete this file?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.toogle-note {
  color: #0d6efd;
}
</style>
