<template>
  <div class="send-file">
    <b-modal
      id="sendFileDownloadAccess"
      centered
      size="md"
      hide-footer
      @shown="getData"
    >
      <template #modal-header>
        <div class="membersendFile-header text-center">
          <h4>Send Download Link</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="sendInfo-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(sendEmail)"
              ref="sendEmailContainer"
            >
              <div class="form-group row">
                <input-text
                  :rules="{ required: true, email: true }"
                  labelFor="email"
                  labelName="Email"
                  inputName="receiver_email"
                  :vmodel.sync="form.email"
                  formGroupClass="col-xxl-12 col-xl-12 col-lg-12 mb-3"
                  :api-errors="apiErrors"
                />

                <div class="form-group col-xxl-12 col-xl-12 col-lg-12 mb-3">
                  <b-button
                    class="univ-btn send-email-btn"
                    type="submit"
                    :disabled="isSend"
                  >
                    {{ isSend ? "Sending ..." : "Send Link" }}
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
let openAxiosInstance = axios.create({ headers: "" });
openAxiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
const formData = {
  email: "",
  file_url: "",
  platform_user_id: "",
  platform_user_type: "",
};

export default {
  name: "FileDownloadAccess",
  props: {
    row: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    form: { ...formData },
    isSend: false,
    apiErrors: null,
  }),
  methods: {
    async sendEmail() {
      const url = `api.access/v2/file-share/add-download-access`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendEmailContainer,
      });
      await openAxiosInstance
        .post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.form = { ...formData };
          this.closeModal();
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
          this.isSend = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("sendFileDownloadAccess");
      this.$emit("onCloseModal");
    },
    getData() {
      this.form = {
        email: "",
        file_url: this.row.doc_url,
        platform_user_id: this.rep.id,
        platform_user_type: "REP",
      };
    },
  },
};
</script>
<style scoped>
.send-email-btn {
  max-width: none;
}
</style>
