var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-file"
  }, [_c('b-modal', {
    attrs: {
      "id": "viewFile",
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "adduploadFile-header text-center"
        }, [_c('h4', [_vm._v("View File")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Type:")]), _c('p', [_vm._v(_vm._s(_vm.row.doc_type))])]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Date:")]), _c('p', [_vm._v(_vm._s(_vm.row.doc_date))])]), _vm.row.uploaded_by ? _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Uploaded By:")]), _c('p', [_vm._v(_vm._s(_vm.row.uploaded_by))])]) : _vm._e(), _vm.row.doc_url ? _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3 action-icons"
  }, [_c('label', [_vm._v("Download:")]), _c('br'), _c('download-button', {
    attrs: {
      "link": _vm.row.doc_url,
      "title": _vm.row.file_title
    }
  }), _vm._v(" " + _vm._s(_vm.row.file_title) + " ")], 1) : _vm._e(), _c('div', {
    staticClass: "clearfix"
  }), _c('div', {
    staticClass: "col-xxl-12"
  }, [_c('label', [_vm._v("Notes:")]), _c('p', [_vm._v(_vm._s(_vm.row.doc_notes))])]), _c('div', {
    staticClass: "clearfix"
  })])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }