var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-file"
  }, [_c('b-modal', {
    attrs: {
      "id": "sendFileUploadAccess",
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "membersendFile-header text-center"
        }, [_c('h4', [_vm._v("Send Upload Link")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "sendInfo-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "sendEmailContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.sendEmail);
            }
          }
        }, [_c('div', {
          staticClass: "action-icons"
        }, [_c('custom-button', {
          attrs: {
            "class-name": "float-right",
            "title": "Add Email",
            "iconClass": "fa fa-plus"
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.addEmail.apply(null, arguments);
            }
          }
        })], 1), _c('div', {
          staticClass: "clearfix"
        }), _vm._l(_vm.form.data, function (input, i) {
          return _c('b-card', {
            key: i,
            staticClass: "file-card"
          }, [_c('div', {
            staticClass: "form-group row"
          }, [_c('b-form-group', {
            staticClass: "col-xxl-6 col-xl-6 col-lg-6 mb-3"
          }, [_c('validation-provider', {
            attrs: {
              "name": "name",
              "rules": {
                required: true
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-input', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext),
                    "type": "name",
                    "labelFor": "name",
                    "labelName": "Name",
                    "inputName": "name",
                    "placeholder": "Enter name"
                  },
                  model: {
                    value: input.name,
                    callback: function callback($$v) {
                      _vm.$set(input, "name", $$v);
                    },
                    expression: "input.name"
                  }
                }), _vm.apiErrors || validationContext.errors ? _c('b-form-invalid-feedback', {
                  attrs: {
                    "id": "input-feedback"
                  }
                }, [_vm._v(" " + _vm._s(_vm.apiErrors && _vm.apiErrors["data.".concat(i, ".name")][0] ? _vm.apiErrors["data.".concat(i, ".name")][0] : validationContext.errors[0]) + " ")]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('b-form-group', {
            staticClass: "col-xxl-6 col-xl-6 col-lg-6 mb-3"
          }, [_c('validation-provider', {
            attrs: {
              "name": "email",
              "rules": {
                required: true,
                email: true
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-input', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext),
                    "type": "email",
                    "labelFor": "email",
                    "labelName": "Email",
                    "inputName": "email",
                    "placeholder": "Enter email"
                  },
                  model: {
                    value: input.email,
                    callback: function callback($$v) {
                      _vm.$set(input, "email", $$v);
                    },
                    expression: "input.email"
                  }
                }), _vm.apiErrors || validationContext.errors ? _c('b-form-invalid-feedback', {
                  attrs: {
                    "id": "input-feedback"
                  }
                }, [_vm._v(" " + _vm._s(_vm.apiErrors && _vm.apiErrors["data.".concat(i, ".email")][0] ? _vm.apiErrors["data.".concat(i, ".email")][0] : validationContext.errors[0]) + " ")]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('b-form-group', {
            staticClass: "col-xxl-12 col-xl-12 col-lg-12 mb-3"
          }, [_c('validation-provider', {
            attrs: {
              "name": "message",
              "rules": {
                required: false
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-textarea', {
                  attrs: {
                    "id": "message",
                    "state": _vm.getValidationState(validationContext),
                    "placeholder": "Enter message"
                  },
                  model: {
                    value: input.message,
                    callback: function callback($$v) {
                      _vm.$set(input, "message", $$v);
                    },
                    expression: "input.message"
                  }
                }), _vm.apiErrors || validationContext.errors ? _c('b-form-invalid-feedback', {
                  attrs: {
                    "id": "input-feedback"
                  }
                }, [_vm._v(" " + _vm._s(_vm.apiErrors && _vm.apiErrors["data.".concat(i, ".message")][0] ? _vm.apiErrors["data.".concat(i, ".message")][0] : validationContext.errors[0]) + " ")]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "remove-email-action action-icons"
          }, [_c('custom-button', {
            attrs: {
              "class-name": "float-right",
              "title": "Delete",
              "iconClass": "fas fa-trash-alt"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.removeEmail(i);
              }
            }
          })], 1)], 1)]);
        }), _c('div', {
          staticClass: "form-group col-xxl-12 col-xl-12 col-lg-12 mb-3 pull-right"
        }, [_c('b-button', {
          staticClass: "univ-btn send-email-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm._v(" " + _vm._s(_vm.isSend ? "Sending ..." : "Send Email") + " ")])], 1)], 2)];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }