var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('simple-table', {
    attrs: {
      "columns": _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "tableBody",
      fn: function fn() {
        return [_c('tbody', [!_vm.rows ? _c('tr', [_c('td', {
          attrs: {
            "colspan": 5,
            "align": "center"
          }
        }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows, function (row, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            attrs: {
              "width": "15%"
            }
          }, [_c('span', {
            staticClass: "date"
          }, [_vm._v(" " + _vm._s(_vm._f("date_format")(row.doc_date, "YYYY/MM/DD HH:mm:ss", "MM/DD/YYYY")) + " ")]), _c('span', {
            staticClass: "time"
          }, [_vm._v(" " + _vm._s(_vm._f("date_format")(row.doc_date, "YYYY/MM/DD HH:mm:ss", "hh:mm A")) + " ")])]), _c('td', {
            attrs: {
              "width": "15%"
            }
          }, [_c('div', [_vm._v(" Title : " + _vm._s(row.file_title) + " ")]), row.uploaded_by ? _c('div', [_vm._v(" Uploaded By :" + _vm._s(row.uploaded_by) + " ")]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "30%"
            }
          }, [_c('div', {
            attrs: {
              "id": 'file-' + row.doc_id
            }
          }, [_vm.showFileId == row.doc_id ? [_vm._v(" " + _vm._s(row.doc_notes) + " ")] : [_vm._v(" " + _vm._s(_vm._f("truncate")(row.doc_notes, 100)) + " ")]], 2), row.doc_notes.length >= 100 ? _c('div', [_c('a', {
            staticClass: "float-right toogle-note",
            attrs: {
              "href": "javascript:void(0)"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleNote(row.doc_id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.showFileId == row.doc_id ? "Show Less" : "Show More") + " ")])]) : _vm._e()]), _c('td', [_c('strong', [_vm._v(_vm._s(row.doc_type))])]), _c('td', {
            attrs: {
              "width": "15%"
            }
          }, [_c('div', {
            staticClass: "action-icons"
          }, [_c('custom-button', {
            attrs: {
              "title": "Send Download Link",
              "iconClass": "fa fa-send"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.sendDownloadLink(row);
              }
            }
          }), _c('custom-button', {
            attrs: {
              "title": "View Detail",
              "iconClass": "fas fa-eye"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.viewDetail(row);
              }
            }
          }), _c('custom-button', {
            attrs: {
              "title": "Delete",
              "iconClass": "fas fa-trash-alt"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.deleteRow(row.doc_id);
              }
            }
          })], 1)])]);
        })], 2)];
      },
      proxy: true
    }])
  }), _c('file-view', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = {};
      }
    }
  }), _c('file-download-access', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = {};
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }