<template>
  <div class="page-wrapper myfiles-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>My Files</h1>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="files-action">
            <ul>
              <li>
                <custom-button
                  title="Upload File"
                  iconClass="fas fa-upload"
                  @click.native="$bvModal.show('adduploadFile')"
                  className="addAdrs-btn"
                  haveText
                />
              </li>
              <li>
                 <custom-button
                  title="Send Upload File Link"
                  iconClass="fa fa-send-o"
                  @click.native="$bvModal.show('sendFileUploadAccess')"
                  className="addAdrs-btn"
                  haveText
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="fileListing-card">
            <file-list-table :rows.sync="rows" @onSuccess="getAll" />
          </div>
        </div>
      </div>
    </div>
    <file-add @onSuccess="getAll" />
    <file-upload-access/>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import FileAdd from "./FileAdd.vue";
import FileListTable from "./partials/FileListTable.vue";
import FileUploadAccess from './partials/FileUploadAccess.vue';
export default {
  components: { FileAdd, FileListTable, FileUploadAccess },
  name: "FileList",
  data: () => ({
    rows: [],
    breadcrumbItems: [{ title: "My Files", active: true }],
  }),
  methods: {
    getAll() {
      let url;
      let loader = this.$loading.show();
      url = `v2/doc/list`;
      Api.get(url)
        .then((res) => {
          this.rows = res.data.data;
        })
        .catch(() => {
          this.rows = [];
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
<style src="@/assets/css/myfiles.css"></style>
