var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper myfiles-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "files-action"
  }, [_c('ul', [_c('li', [_c('custom-button', {
    attrs: {
      "title": "Upload File",
      "iconClass": "fas fa-upload",
      "className": "addAdrs-btn",
      "haveText": ""
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.$bvModal.show('adduploadFile');
      }
    }
  })], 1), _c('li', [_c('custom-button', {
    attrs: {
      "title": "Send Upload File Link",
      "iconClass": "fa fa-send-o",
      "className": "addAdrs-btn",
      "haveText": ""
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.$bvModal.show('sendFileUploadAccess');
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "fileListing-card"
  }, [_c('file-list-table', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      },
      "onSuccess": _vm.getAll
    }
  })], 1)])])], 1), _c('file-add', {
    on: {
      "onSuccess": _vm.getAll
    }
  }), _c('file-upload-access')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("My Files")])]);
}]

export { render, staticRenderFns }