var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-file"
  }, [_c('b-modal', {
    attrs: {
      "id": "sendFileDownloadAccess",
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "membersendFile-header text-center"
        }, [_c('h4', [_vm._v("Send Download Link")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "sendInfo-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "sendEmailContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.sendEmail);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              email: true
            },
            "labelFor": "email",
            "labelName": "Email",
            "inputName": "receiver_email",
            "vmodel": _vm.form.email,
            "formGroupClass": "col-xxl-12 col-xl-12 col-lg-12 mb-3",
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        }), _c('div', {
          staticClass: "form-group col-xxl-12 col-xl-12 col-lg-12 mb-3"
        }, [_c('b-button', {
          staticClass: "univ-btn send-email-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm._v(" " + _vm._s(_vm.isSend ? "Sending ..." : "Send Link") + " ")])], 1)], 1)])];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }