<template>
  <div class="upload-file">
    <b-modal id="adduploadFile" centered size="md"  no-close-on-backdrop>
      <template #modal-header>
        <div class="adduploadFile-header text-center">
          <h4>Upload File</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="adduploadFile-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(addFile)"
              ref="addFileContainer"
            >
              <input-text
                :rules="{ required: true }"
                labelFor="file_title"
                labelName="File Title"
                inputName="file_title"
                :vmodel.sync="form.file_title"
                formGroupClass="form-group mb-3"
              />

              <input-multi-select
                :vmodel.sync="form.doc_type"
                labelName="FileType"
                :options="fileTypes.map((type) => type.value)"
                :custom-label="
                  (opt) => fileTypes.find((x) => x.value == opt).text
                "
                 :rules="{ required: true }"
                formGroupClass="form-group mb-3"
                placeholder="Select File Type"
              />

              <input-file
                :rules="{ required: true, mimes: mimeTypes }"
                labelFor="select_file"
                 labelName="Select File (txt, png, jpg, jpeg, xlsx, pdf, csv, doc and pptx) "
                inputName="select_file"
                :vmodel.sync="file"
                :acceptMimeTypes="mimeTypes"
                formGroupClass="form-group mb-3"
              />
              <input-textarea
                :rules="{ required: true }"
                labelFor="file"
                labelName="File Notes "
                inputName="file"
                :vmodel.sync="form.doc_notes"
                formGroupClass="form-group mb-3"
              />

              <div class="col-md-12">
                <b-button class="upload-btn form-control" type="submit" :disabled="isCreate">
                  <template v-if="isCreate"> Uploading ... </template>
                  <template v-else> Upload File </template>
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "FileAdd",
  data() {
    return {
      form: {
        file_title: "",
        doc_notes: "",
        doc_type: null,
      },
      file:null,
      fileTypes: [
        { value: "My Files", text: "My Files" },
        { value: "Forms", text: "Forms" },
        { value: "Enrollment Form", text: "Enrollment Form" },
        { value: "Census File", text: "Census File" },
        { value: "Member Docs", text: "Member Docs" },
        { value: "Rep Doc", text: "Rep Doc" },
        { value: "Commission Statement", text: "Commission Statement" },
      ],
      residents: [
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
      isCreate: false,
      mimeTypes: [
        "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/mspowerpoint",
        "application/powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/excel",
        "application/x-msexcel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "application/pdf",
        "text/csv",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ],
    };
  },
  methods: {
    reset() {
      this.form = {
        file_title: "",
        doc_notes: "",
        doc_type: null,
      };
      this.file = null
      this.$refs.observer.reset();
    },
    closeModal() {
      this.$bvModal.hide("adduploadFile");
      this.reset();
    },
    addFile() {
      let url;
      this.isCreate = true;
      url = `v2/doc/add`;
      let formData = new FormData();
      formData.append("file", this.file);
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      
      let loader = this.$loading.show({
        container: this.$refs.addFileContainer,
      });
      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCreate = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
};
</script>