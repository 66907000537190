<template>
  <div class="view-file">
    <b-modal id="viewFile" centered size="md" hide-footer>
      <template #modal-header>
        <div class="adduploadFile-header text-center">
          <h4>View File</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row">
          <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
            <label>Type:</label>
            <p>{{ row.doc_type }}</p>
          </div>
          <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
            <label>Date:</label>
            <p>{{ row.doc_date }}</p>
          </div>
          <div class="col-xxl-6 col-lg-6 col-md-6 mb-3" v-if="row.uploaded_by">
           <label>Uploaded By:</label>
            <p>{{ row.uploaded_by }}</p>
          </div>
          <div
            class="col-xxl-6 col-lg-6 col-md-6 mb-3 action-icons"
            v-if="row.doc_url"
          >
            <label>Download:</label><br/>
            <download-button :link="row.doc_url" :title="row.file_title" />
            {{row.file_title}}
          </div>
          <div class="clearfix"></div>

          <div class="col-xxl-12">
            <label>Notes:</label>
            <p>{{ row.doc_notes }}</p>
          </div>
          <div class="clearfix"></div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "FileView",
  props: {
    row: {
      required: true,
    },
  },
  computed: {
    fileType() {
      let [filename, ext] = [this.row.doc_url, ""];
      if (filename) {
        ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
      }
      if (ext == "pdf") {
        return "pdf";
      } else if (ext == "png" || ext == "jpeg" || ext == "jpg") {
        return "image";
      } else {
        return "";
      }
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("viewFile");
      this.$emit("onCloseModal");
    },
  },
};
</script>
<style scoped>
.file-doc {
  height: 100px;
  width: 100px;
}
</style>